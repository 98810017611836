import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from '../../contexts/ResumeContext';
import BaseModal from '../BaseModal';
import Button from '../../components/shared/Button';
import ModalContext from '../../contexts/ModalContext';

const ImportModal = () => {
  const { t } = useTranslation();
  const jsonResumeFileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { emitter, events } = useContext(ModalContext);

  useEffect(() => {
    const unbind = emitter.on(events.IMPORT_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const importJsonResume = (event) => {
    const fr = new FileReader();
    fr.addEventListener('load', () => {
      const payload = JSON.parse(fr.result);
      dispatch({ type: 'on_import_jsonresume', payload });
      setOpen(false);
    });
    fr.readAsText(event.target.files[0]);
  };

  return (
    <BaseModal
      hideActions
      state={[open, setOpen]}
      title={t('builder.actions.import.heading')}
    >
      <hr className="my-8" />

      <div>
        <h5 className="text-xl font-semibold mb-4">
          {t('modals.import.jsonResume.heading')}
        </h5>

        <p className="leading-loose">{t('modals.import.jsonResume.text')}</p>

        <Button
          className="mt-5"
          onClick={() => jsonResumeFileInputRef.current.click()}
        >
          {t('modals.import.button')}
        </Button>
        <input
          ref={jsonResumeFileInputRef}
          type="file"
          className="hidden"
          onChange={importJsonResume}
        />
      </div>
    </BaseModal>
  );
};

export default memo(ImportModal);
